html,body {
  background-color: #323a45;
  font-size: 14px;
}
h1 {
  font-family: 'Poiret One', cursive;
  font-size: 5em;
}
.App {
  text-align: center;
  color: #FFFFFF;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section {
  min-height: 100vh;
}
.custom-section {
  position: relative;
}

.scroll-indicator {
  position: absolute;
  z-index: 998;
  left: 0;
  bottom: 1em;
  width: 100vw;
  text-align: center;
  display: block;
  -webkit-animation: bounce 2s 0s ease infinite;
  animation: bounce 2s 0s ease infinite;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background-color: #FFFFFF !important;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-0.8em);
  }
  25% {
    -webkit-transform: translateY(0em);
  }
  100% {
    -webkit-transform: translateY(-0.8em);
  }
}
@-moz-keyframes bounce {
  0% {
    -moz-transform: translateY(-0.8em);
  }
  25% {
    -moz-transform: translateY(0em);
  }
  100% {
    -moz-transform: translateY(-0.8em);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(-0.8em);
    -moz-transform: translateY(-0.8em);
    -ms-transform: translateY(-0.8em);
    -o-transform: translateY(-0.8em);
    transform: translateY(-0.8em);
  }
  25% {
    -webkit-transform: translateY(0em);
    -moz-transform: translateY(0em);
    -ms-transform: translateY(0em);
    -o-transform: translateY(0em);
    transform: translateY(0em);
  }
  100% {
    -webkit-transform: translateY(-0.8em);
    -moz-transform: translateY(-0.8em);
    -ms-transform: translateY(-0.8em);
    -o-transform: translateY(-0.8em);
    transform: translateY(-0.8em);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
  html,body {
    font-size: 16px;
  }
}
